import React, { useEffect, useRef, useState } from 'react'
import CreatePurchases from '../CreatePurchases/CreatePurchases'
import Button from '../../components/Button'
import api from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal, SuccessModal } from '../../components/Modal'
import { isAxiosError } from 'axios'
import { useSelector } from 'react-redux'
import customMainApi from '../../api/index'
import { useTranslation } from 'react-i18next'

const CreatePurchasesPage = () => {
	const { getMe } = useSelector((state) => state.main)

	const successModalRef = useRef()
	const errorRef = useRef()
	const { t } = useTranslation()

	const [showModal, setShowModal] = useState(false)
	const [confirmationMessage, setConfirmationMessage] = useState('')

	const [itemName, setItemName] = useState('')
	const [batteryLife, setBatteryLife] = useState('')
	const [color, setColor] = useState('')
	const [memory, setMemory] = useState('')
	const [condition, setCondition] = useState('')
	const [btnLoading, setBtnLoading] = useState(false)
	const [userFields, setUserFields] = useState([])
	const [series, setSeries] = useState([])
	const [serie, setSerie] = useState('')

	const CreateItemsPost = async () => {
		setBtnLoading(true)

		try {
			await api
				.post(`Items`, {
					ItemName: itemName,
					Series: serie,
					ManageBatchNumbers: 'tNO',
					ManageSerialNumbers: 'tYES',
					CostAccountingMethod: 'bis_SNB',
					U_Color: color,
					U_Condition: condition,
					U_Memory: memory,
					// U_BatteryLife: batteryLife,
				})
				.then((res) => {
					successModalRef.current?.open(t('sucUser'))
					return JSON.parse(res.data).value
				})
			// navigation('/purchases')
		} catch (e) {
			if (isAxiosError(e)) {
				errorRef.current?.open(
					get(JSON.parse(e.response.data), 'error.message', ''),
				)
			}
		} finally {
			setBtnLoading(false)
		}
	}

	const getItemSeries = () => {
		api
			.post('SeriesService_GetDocumentSeries', {
				DocumentTypeParams: {
					Document: '4',
				},
			})
			.then((res) => {
				const items = JSON.parse(res.data).value
				const filteredItems = items.filter((item) => item.IsManual === 'tNO')
				setSeries(filteredItems)
			})
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter:
						"TableName eq 'OITM' and (Name eq 'Memory' or Name eq 'Color')",
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const handlePostBtnClick = () => {
		CreateItemsPost()
	}

	useEffect(() => {
		userFieldsFn()
		getItemSeries()
		// ()
	}, [])

	return (
		<Layout>
			<CreatePurchases>
				<div className="container">
					<div className="overflow-y-auto my-8">
						<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
							<thead className="text-xs text-gray-700 uppercase bg-gray-50 "></thead>
							<tbody>
								<tr className="bg-white  hover:bg-gray-50 ">
									<td className="px-6 py-4 border">
										<div className="searchable-select">
											<div className={'searchableHead'}>
												<div>
													<p>{t('productName')}</p>
													<input
														className={
															'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md w-[300px]'
														}
														type="text"
														onChange={(e) => {
															setItemName(e.target.value)
														}}
														placeholder={t('productName')}
														value={itemName}
													/>
												</div>
											</div>
										</div>
									</td>

									<td className="px-6 border py-4">
										<p>{t('serie')}</p>
										<select
											className={
												'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md'
											}
											onChange={(e) => {
												setSerie(e.target.value)
											}}
											value={serie}
										>
											<option key={''} value={''}></option>
											{series.map((item, idx) => (
												<option key={idx} value={item.Series}>
													{item.Prefix}
												</option>
											))}
										</select>
									</td>

									<td className="px-6 border py-4">
										<p>{t('condition')}</p>
										<select
											className={
												'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md'
											}
											onChange={(event) => {
												setCondition(event.target.value)
												if (event.target.value === 'NEW') {
													setBatteryLife('100%')
												}
											}}
											value={condition}
										>
											<option value=""></option>
											<option value="NEW"> NEW</option>
											<option value="B/U"> B/U</option>
										</select>
									</td>

									{/* <td className="px-6 border py-4">
										<div>
											<p>Ёмкость</p>
											<input
												className={
													'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md w-[150px]'
												}
												type="text"
												onChange={(e) => {
													setBatteryLife(e.target.value)
												}}
												placeholder="Ёмкость"
												value={batteryLife}
												disabled={condition === 'NEW'}
											/>
										</div>
									</td> */}
									<td className="px-6 border py-4">
										<p>{t('color')}</p>
										<select
											className={
												'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md'
											}
											onChange={(event) => setColor(event.target.value)}
											value={color}
										>
											<option value=""> </option>
											{userFields
												?.find((item) => item.Name === 'Color')
												?.ValidValuesMD.map((value) => (
													<option value={value.Value}>
														{value.Description}
													</option>
												))}
										</select>
									</td>

									<td className="px-6 border py-4">
										<p>{t('memory')}</p>
										<select
											className={
												'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md'
											}
											onChange={(event) => setMemory(event.target.value)}
											value={memory}
										>
											<option value=""> </option>
											{userFields
												?.find((item) => item.Name === 'Memory')
												?.ValidValuesMD.map((value) => (
													<option value={value.Value}>
														{value.Description}
													</option>
												))}
										</select>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'end',
							paddingRight: '50px',
							paddingTop: '30px',
						}}
					>
						<Button isLoading={btnLoading} onClick={handlePostBtnClick}>
							{t('create')}
						</Button>
					</div>
				</div>
			</CreatePurchases>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
				<SuccessModal
					getRef={(r) => {
						successModalRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default CreatePurchasesPage
