import React, { useEffect, useRef, useState } from 'react'
import AllProductsStyle from './AllProducts'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi } from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'
import { roundCurrency } from '../../utils'
import { isAxiosError } from 'axios'
import _ from 'lodash'
import { instalmentPlan } from '../../store/slices'
import { useDispatch, useSelector } from 'react-redux'
import customMainApi from '../../api/index'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Empty, DatePicker } from 'antd'
import { useTranslation } from 'react-i18next'

const { RangePicker } = DatePicker

const handleBusinessPartner = _.debounce((searchFunc, value) => {
	searchFunc(value)
}, 1000)

const AllProducts = () => {
	dayjs.extend(advancedFormat)
	dayjs.extend(customParseFormat)

	const { t } = useTranslation()

	const {
		setClient: setClientStore,
		setImei: setImeiStore,
		setCardCode: setCardCodeStore,
	} = instalmentPlan.actions
	const {
		client: clientStore,
		imei: imeiStore,
		cardCode: cardCodeStore,
	} = useSelector((state) => state.instalmentPlan)
	const dispatch = useDispatch()

	const navigation = useNavigate()
	const errorRef = useRef()
	const dateFormat = 'YYYY-MM-DD'
	const [dateStart, setDateStart] = useState(
		dayjs().startOf('year').format(dateFormat),
	)
	const [dateEnd, setDateEnd] = useState(dayjs().format(dateFormat))

	const [isLoading, setIsLoading] = useState(false)
	const [clientLoading, setClientLoading] = useState(false)
	const [client, setClient] = useState(clientStore)
	const [clientCode, setClientCode] = useState(cardCodeStore)
	const [imeiClient, setImeiClient] = useState(imeiStore)
	const [product, setProduct] = useState('')
	const [data, setData] = useState([])
	const [currentPage, setCurrentPage] = useState(0)
	const [clientsData, setClientsData] = useState([])
	const [userFields, setUserFields] = useState([])

	const search = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getInvoices`, {
				params: {
					type: 'manyinst',

					$skip: currentPage,
					IntrSerial: imeiClient || undefined,
					CardCode: client && clientCode ? clientCode : undefined,
					itemCode: product || undefined,
				},
				headers: {
					Prefer: 'odata.maxpagesize=10',
				},
			})
			.then((res) => {
				const resData = res.data.value || []
				setData(resData)
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err)) errorRef.current?.open(get(err, 'message', ''))
				setIsLoading(false)
			})
	}

	useEffect(() => {
		userFieldsFn()
	}, [])

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OITM' ",
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	useEffect(() => {
		if (!client) {
			dispatch(setClientStore(client))
		}
	}, [client])

	const businessPartners = async (value) => {
		setClientLoading(true)
		const formattedName = value.includes("'")
			? value.replace(/'/g, "''")
			: value

		api
			.get(`BusinessPartners`, {
				params: {
					$select: 'CardCode, CardName, U_Telephone',
					$filter: `contains(CardName, '${formattedName}') or contains(U_Telephone, '${formattedName}') or contains(CardCode, '${formattedName}')`,
				},
				headers: {
					Prefer: 'odata.maxpagesize=10',
				},
			})
			.then((res) => {
				setClientsData(JSON.parse(res.data).value || [])
				setClientLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err)) errorRef.current?.open(get(err, 'message', ''))
				setIsLoading(false)
			})
	}

	useEffect(() => {
		search()
	}, [currentPage])

	const viewItem = (v) => {
		const url = `/viewItem?data=${encodeURIComponent(JSON.stringify(v))}&DocEntry=${v.DocEntry}`
		window.open(url, '_blank')
	}

	const newDatas = () => {
		if (data.length >= 10) {
			setCurrentPage(currentPage + 10)
		} else {
			alert(t('noOtherInfo'))
		}
	}

	const oldData = () => {
		if (data.oldPage < 0) {
			alert(t('noOtherInfo'))
		} else {
			setCurrentPage(currentPage - 10)
		}
	}

	const handleSearch = () => {
		search()
	}
	const onRangeChange = (dates, dateStrings) => {
		if (dates) {
			const start = dates[0]
			const end = dates[1]

			setDateStart(start ? start.format('YYYY-MM-DD') : null)
			setDateEnd(end ? end.format('YYYY-MM-DD') : null)
		} else {
			console.error('Clear in Installment Plan ')
		}
	}

	return (
		<Layout>
			<AllProductsStyle>
				<div className="container">
					<p className="productTitle">{t('installment-plan')}</p>
					<div className="flex items-center justify-between items-center">
						<div className="flex items-center mt-10 mb-10 gap-8">
							<div className="relative">
								<input
									className={'border-[1px] border-[#d9d9d9] p-2 rounded-md '}
									type="text"
									placeholder={t('client')}
									value={client}
									onChange={(e) => {
										setClient(e.target.value)
										handleBusinessPartner(businessPartners, e.target.value)
									}}
								/>
								{client && (
									<div className="absolute top-10 left-0 bg-white w-full z-10 border-[1px] border-[#d9d9d9] max-h-[300px] overflow-y-scroll">
										{clientsData.map((v, i) => {
											return (
												<div
													key={i}
													className="p-2 border-b cursor-pointer hover:bg-gray-100"
													onClick={() => {
														dispatch(setCardCodeStore(v.CardCode))
														dispatch(setClientStore(v.CardName))
														setClientCode(v.CardCode)
														setClient(v.CardName)
														setClientsData([])
													}}
												>
													{v.CardName}
												</div>
											)
										})}
									</div>
								)}
							</div>
							<input
								className={'border-[1px] border-[#d9d9d9"] p-2 rounded-md '}
								type="text"
								placeholder={t('imei')}
								defaultValue={imeiClient}
								onChange={(v) => {
									setImeiClient(v.target.value)
									dispatch(setImeiStore(v.target.value))
								}}
							/>
							<div className="flex flex-col w-full">
								<RangePicker
									value={[
										dateStart ? dayjs(dateStart, dateFormat) : null,
										dateEnd ? dayjs(dateEnd, dateFormat) : null,
									]}
									format={dateFormat}
									onChange={onRangeChange}
									className='border-[1px] border-[#DFE2E9"] p-2 rounded-md min-w-[240px] w-full '
								/>
							</div>
							{/*<input*/}
							{/*  className={'border-[1px] border-[#d9d9d9"] p-2 rounded-md '}*/}
							{/*  type="text"*/}
							{/*  placeholder="Товар"*/}
							{/*  defaultValue={product}*/}
							{/*  onChange={(v) => setProduct(v.target.value)}*/}
							{/*/>*/}
							<Button onClick={() => handleSearch()} isLoading={isLoading}>
								{t('search')}
							</Button>
						</div>
						<Button
							className={'btn'}
							onClick={() =>
								window.open('/createProducts-installment', '_blank')
							}
						>
							{t('add')}
						</Button>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto mb-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												{t('clientCode')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('clientName')}
											</th>

											{/* <th scope="col" className="px-6 py-4">
												Гарант
											</th> */}
											<th scope="col" className="px-6 py-4">
												{t('imei')}
											</th>
											<th scope="col" className="px-20 py-4">
												{t('product')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('page')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('icloud')}
											</th>

											<th scope="col" className="px-6 py-4">
												{t('debtToday')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('debtTotal')}
											</th>

											<th scope="col" className="px-6 py-4">
												{t('monthlyPayment')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('dateOfSale')}
											</th>
										</tr>
									</thead>
									<tbody>
										{data.length > 0 ? (
											data.map((v, i) => {
												const color =
													get(v, 'U_Color', '') !== ''
														? userFields
																?.find((item) => item.Name === 'Color')
																?.ValidValuesMD.find(
																	(value) =>
																		get(v, 'U_Color', '') === value.Value,
																)?.Description || ''
														: ''

												const memory =
													get(v, 'U_Memory', '') !== ''
														? userFields
																?.find((item) => item.Name === 'Memory')
																?.ValidValuesMD.find(
																	(value) =>
																		get(v, 'U_Memory', '') === value.Value,
																)?.Description || ''
														: ''

												const condition =
													get(v, 'U_Condition', '') !== ''
														? userFields
																?.find((item) => item.Name === 'Condition')
																?.ValidValuesMD.find(
																	(value) =>
																		get(v, 'U_Condition', '') === value.Value,
																)?.Description || ''
														: ''

												return (
													<tr
														key={i}
														onClick={() => viewItem(v)}
														className="bg-white border-b  hover:bg-gray-50 "
													>
														<td
															scope="row"
															className="px-6 py-4 font-medium text-gray-900 "
														>
															{get(v, 'CardCode', '-')}
														</td>
														<td className="px-6 py-4">
															{get(v, 'CardName', '-')}
														</td>
														{/* <td className="px-6 py-4">
														{get(v, 'U_Guarantor', '-')}
													</td> */}
														<td className="px-6 py-4">
															{get(v, 'IntrSerial', '-')}
														</td>
														<td className="px-6 py-4">
															{get(v, 'Dscription', '')} {color} {memory}{' '}
															{condition}
														</td>
														<td className="px-6 py-4">
															{get(v, 'U_DaftarBet', '-')}
														</td>{' '}
														<td className="px-6 py-4">
															{get(v, 'U_Security.Name', '-')}
														</td>
														<td className="px-6 py-4">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(get(v, 'DueToDate', '-'))
																.replace(',', '.')}
														</td>
														<td className="px-6 py-4">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(
																	get(v, 'DocTotal', '-') -
																		get(v, 'PaidToDate', '-'),
																)
																.replace(',', '.')}
														</td>
														<td className="px-6 py-4">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(get(v, 'MonthlyTotal', '-'))
																.replace(',', '.')}
														</td>
														<td className="px-6 py-4">
															{moment(get(v, 'DocDate', '-')).format(
																'DD-MM-YYYY',
															)}
														</td>
													</tr>
												)
											})
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description={t('noInfo')}
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="topCard">
								<div className="flex items-center gap-2 ">
									<Button
										disabled={currentPage === 0}
										className={'btn'}
										onClick={oldData}
									>
										{'<'}
									</Button>
									<p>{currentPage / 10 + 1 || 1}</p>
									<Button
										disabled={data.length < 10}
										className={'btn'}
										onClick={newDatas}
									>
										{'>'}
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</AllProductsStyle>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default AllProducts
