import React, { useEffect, useRef, useState } from 'react'
import CashFlowReport from './CashFlowReport'
import Button from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { customFuncsApi } from '../../../api'
import { get } from 'lodash'
import Layout from '../../../components/Layout'
import { ErrorModal } from '../../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

const CashFlowReportPage = () => {
	const errorRef = useRef()
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState(false)
	const [startDate, setStartDate] = useState(
		new Date().toISOString().split('T')[0],
	)
	const [data, setData] = useState({
		data: [],
		currentPage: 0, //20
		nextPage: 0, //30
		oldPage: 0,
	})
	const search = (currentPage = 0, oldDataPage = 0) => {
		setIsLoading(true)
		customFuncsApi
			.get(
				`/getCashAccountsStats?date=${encodeURIComponent(startDate + 'T00:00:00.000Z')}`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=10',
					},
				},
			)
			.then((res) => {
				const resData = res.data.value
				const nextPage = res.data['@odata.nextLink'].split('skip=')[1]

				if (oldDataPage === 0) {
					setData({
						data: [...resData],
						currentPage: currentPage === 0 ? 0 : data.nextPage,
						nextPage: nextPage === 0 ? data.currentPage : nextPage,
						oldPage: nextPage === 0 ? data.currentPage : nextPage - 20,
					})
				} else {
					setData({
						data: [...resData],
						currentPage: data.nextPage - 20,
						nextPage: nextPage,
						oldPage: nextPage < data.currentPage ? nextPage : nextPage - 20,
					})
				}
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err))
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		search(0, 0)
	}, [startDate])

	const viewItem = (v) => {
		const url = `/viewItem?data=${encodeURIComponent(JSON.stringify(v))}&DocEntry=${v.DocEntry}`
		window.open(url, '_blank')
	}
	const newDatas = () => {
		if (data.nextPage > data.currentPage) {
			search(data.nextPage, 0)
		} else {
			alert(t("noOtherInfo"))
		}
	}

	const oldData = () => {
		if (data.oldPage < 0) {
			alert(t("noOtherInfo"))
		} else {
			search(data.oldPage, 1)
		}
	}

	const handleStartDateChange = (event) => {
		setStartDate(event.target.value)
	}

	return (
		<Layout>
			<CashFlowReport>
				<div className="container">
					<div className="mt-4">
						<p className="productTitle">{t("products")}</p>
					</div>
					<div className="searchCard mt-8">
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t("startDate")}
							</p>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'
								}
								type="date"
								value={startDate}
								onChange={handleStartDateChange}
							/>
						</div>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto my-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
											{t("clientCode")}
											</th>
											<th scope="col" className="px-6 py-4">
												{t("clientName")}
											</th>
											<th scope="col" className="px-6 py-4">
												{t("product")}
											</th>
											<th scope="col" className="px-6 py-4">
												{t("money")}
											</th>
											<th scope="col" className="px-6 py-4">
											{t("dateOfSale")}
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.length > 0 ? (
											data.data.map((v, i) => (
												<tr
													key={i}
													onClick={() => viewItem(v)}
													className="bg-white border-b  hover:bg-gray-50 "
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{get(v, 'Invoices.CardCode', 'Код покупателя')}
													</td>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{get(v, 'Invoices.CardName', 'Имя покупателя')}
													</td>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{get(
															v,
															'Invoices/DocumentLines.ItemDescription',
															'',
														)}
													</td>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{' '}
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(
																get(v, 'Invoices/DocumentLines.LineTotal', ''),
															)
															.replace(',', '.')}{' '}
														USD{' '}
													</td>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{get(v, 'Invoices.DocDate', '')}
													</td>
												</tr>
											))
										) : (
											<tr >
												<td colSpan="10" >
													<Empty description={t("noInfo")} image={Empty.PRESENTED_IMAGE_SIMPLE} />
												</td>
											</tr>										)}
									</tbody>
								</table>
							</div>
							<div className="topCard">
								<div className="flex">
									<Button
										className={'btn'}
										onClick={oldData}
										btnStyle={{ marginRight: 10 }}
									>
										{'<'}
									</Button>
									<Button className={'btn'} onClick={newDatas}>
										{'>'}
									</Button>
								</div>
								{/*<Button*/}
								{/*  className={'btn'}*/}
								{/*  onClick={() => navigation('/createProducts')}*/}
								{/*>*/}
								{/*  {'+'}*/}
								{/*</Button>*/}
							</div>
						</>
					)}
				</div>
			</CashFlowReport>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default CashFlowReportPage
