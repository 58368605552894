import React, { useEffect, useRef, useState } from 'react'
import Purchases from './Purchases'
import Button from '../../components/Button'
import { customFuncsApi } from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import PurchaseModal from '../../components/Modal/PurchaseModal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import moment from 'moment'
import customMainApi from '../../api/index'
import { Empty, DatePicker } from 'antd'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const { RangePicker } = DatePicker

const PurchasesPage = () => {
	const errorRef = useRef()
	const infoModalRef = useRef()
	const { getMe } = useSelector((state) => state.main)
	const dateFormat = 'YYYY-MM-DD'
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState(false)
	const [startDate, setStartDate] = useState(dayjs().format(dateFormat))
	const [endDate, setEndDate] = useState(dayjs().format(dateFormat))
	const [data, setData] = useState({
		data: [],
	})

	const [pagination, setPagination] = useState({
		page: 0,
	})
	const [userFields, setUserFields] = useState([])

	const search = () => {
		setIsLoading(true)
		customFuncsApi
			.get(
				`xsSql/getPurchases?date1='${startDate + 'T00%3A00%3A00.000Z'}'&date2='${endDate + 'T00%3A00%3A00.000Z'}'&$skip=${pagination.page * 20}${getMe?.Department2.Name === 'Sotuv' || getMe?.Department2.Name === 'DokonManager' ? `&WhsCode=${getMe?.U_Warehouse}` : ''}`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=20',
					},
				},
			)
			.then((res) => {
				const resData = res.data.value

				setData({
					data: [...resData],
				})
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err))
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				setIsLoading(false)
			})
	}

	const generatePDF = () => {
		setIsLoading(true)
		customFuncsApi
			.get(
				`generatePurchasesSpreadsheet?date1='${startDate + 'T00%3A00%3A00.000Z'}'&date2='${endDate + 'T00%3A00%3A00.000Z'}'${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `&WhsCode='${get(getMe, 'U_Warehouse', '')}'` : ''}`,
				{
					headers: {
						'Content-Type':
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					},
					responseType: 'blob',
				},
			)
			.then((res) => {
				const url = window.URL.createObjectURL(
					new Blob([res.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					}),
				)
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', 'Purchases.xlsx')
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				setIsLoading(false)

			})
			.catch((err) => {
				if (isAxiosError(err))
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		search(0, 0)
	}, [startDate, startDate, endDate, pagination])

	useEffect(() => {
		userFieldsFn()
	}, [])
	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OITM' ",
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const newDatas = () => {
		if (data.data.length < 20) {
			alert(t('noOtherInfo'))
		} else {
			setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
		}
	}

	const oldData = () => {
		if (pagination.page > 0) {
			setPagination((prev) => ({ ...prev, page: prev.page - 1 }))
		} else {
			alert(t('noOtherInfo'))
		}
	}

	const handleStartDateChange = (date) => {
		setStartDate(date ? date.format('YYYY-MM-DD') : null)
	}

	const handleEndDateChange = (date) => {
		setEndDate(date ? date.format('YYYY-MM-DD') : null)
	}
	const onRangeChange = (dates, dateStrings) => {
		if (dates) {
			const start = dates[0]
			const end = dates[1]

			handleStartDateChange(start)
			handleEndDateChange(end)
		} else {
			console.log('Clear')
		}
	}

	return (
		<Layout>
			<Purchases>
				<div className="container">
					<p className="productTitle">{t('purchases')}</p>
					<div className={'flex justify-between pr-5'}>
						<RangePicker
							value={[
								startDate ? dayjs(startDate, dateFormat) : null,
								endDate ? dayjs(endDate, dateFormat) : null,
							]}
							format={dateFormat}
							onChange={onRangeChange}
						/>

						<Button
							className={'btn'}
							onClick={() => window.open('/create-purchases', '_blank')}
						>
							{t('add')}
						</Button>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto my-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												{t('docNum')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('customer')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('model')}
											</th>

											<th scope="col" className="px-6 py-4">
												{t('quantity')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('price')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('purchaseDate')}
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.length > 0 ? (
											data.data.map((v, i) => {
												const color =
													get(v, 'U_Color', '') !== ''
														? userFields
																?.find((item) => item.Name === 'Color')
																?.ValidValuesMD.find(
																	(value) =>
																		get(v, 'U_Color', '') === value.Value,
																)?.Description || ''
														: ''

												const memory =
													get(v, 'U_Memory', '') !== ''
														? userFields
																?.find((item) => item.Name === 'Memory')
																?.ValidValuesMD.find(
																	(value) =>
																		get(v, 'U_Memory', '') === value.Value,
																)?.Description || ''
														: ''

												const condition =
													get(v, 'U_Condition', '') !== ''
														? userFields
																?.find((item) => item.Name === 'Condition')
																?.ValidValuesMD.find(
																	(value) =>
																		get(v, 'U_Condition', '') === value.Value,
																)?.Description || ''
														: ''

												return (
													<tr
														key={i}
														className="bg-white border-b  hover:bg-gray-50 "
														onClick={() =>
															infoModalRef.current?.open(v.DocEntry)
														}
													>
														<td className="px-6 py-4 font-medium text-gray-900 ">
															{v.DocNum || '-'}
														</td>
														<td className="px-6 py-4 font-medium text-gray-900 ">
															{v.CardName || '-'}
														</td>
														<td className="px-6 py-4">
															{get(v, 'Dscription', '').includes('|||') &&
															get(v, 'Dscription', '').split('|||')[1]
																? `${get(v, 'Dscription', '').split('|||')[0]} ${color} ${memory} ${condition} ...`
																: `${get(v, 'Dscription', '')} ${color} ${memory} ${condition}`}
														</td>
														<td className="px-6 py-4">{+v.Quantity || '-'}</td>
														<td className="px-6 py-4">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(v.DocTotal)
																.replace(',', '.')}{' '}
															USD
														</td>
														<td className="px-6 py-4">
															{moment(v.DocDate).format('DD-MM-YYYY')}
														</td>
													</tr>
												)
											})
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description={t('noInfo')}
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>

							<div className="flex items-center justify-between mb-3">
								<div className="flex items-center gap-2">
									<button
										className={
											'w-[40px] h-[40px] rounded-xl	 bg-[#0A4D68] text-white'
										}
										onClick={oldData}
									>
										{'<'}
									</button>
									<button
										className={
											'w-[40px] h-[40px] rounded-xl	 bg-[#0A4D68] text-white'
										}
										onClick={newDatas}
									>
										{'>'}
									</button>
								</div>
								<div className="mr-4">
									<Button className={'btn'} onClick={generatePDF}>
										{t('downloadExcel')}
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</Purchases>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
				<PurchaseModal
					getRef={(r) => {
						infoModalRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default PurchasesPage
