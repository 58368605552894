import React, { useEffect, useRef, useState } from 'react'
import { ErrorModal, SuccessModal, WarningModal } from '../../components/Modal'
import Progressbar from '../../components/Progress'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/Layout'
import Button from '../../components/Button'
import RecoveryStyle from './RecoveryStyle'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import api, { customFuncsApi } from '../../api'
import moment from 'moment'
import ClipLoader from 'react-spinners/ClipLoader'
import { recovery as RecoveryStore } from '../../store/slices'
import customMainApi from '../../api/index'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Empty, DatePicker } from 'antd'
import { useTranslation } from 'react-i18next'

const { RangePicker } = DatePicker
const Recovery = () => {
	const { t } = useTranslation()

	dayjs.extend(advancedFormat)
	dayjs.extend(customParseFormat)
	const { getMe } = useSelector((state) => state.main)
	const {
		setName,
		setImei,
		setStartDate,
		setEndDate,
		setStatus: setStatusStore,
		setExecuter: setExecuterStore,
		setPage: setPageStore,
	} = RecoveryStore.actions
	const {
		name,
		imei: imeiStore,
		startDate,
		endDate,
		status: statusStore,
		executer: executerStore,
		currentPage: pageStore,
	} = useSelector((state) => state.recovery)

	const navigate = useNavigate()

	const startOfMonth = dayjs().startOf('month')
	const endOfMonth = dayjs().endOf('month')
	const startDateFormatted = startOfMonth.format('YYYY-MM-DD')
	const endDateFormatted = endOfMonth.format('YYYY-MM-DD')
	const dateFormat = 'YYYY-MM-DD'

	const errorRef = useRef()
	const successRef = useRef()
	const warningRef = useRef()

	const [isLoading, setIsLoading] = useState(false)
	const [isProtsent, setIsProtsent] = useState(false)

	const [status, setStatus] = useState(statusStore)
	const [clientName, setClientName] = useState(name)
	const [imei, setIMEI] = useState(imeiStore)
	const [userCardCode, setUserCardCode] = useState('')
	const [numberProtsent, setnumberProtsent] = useState(1)
	const [dateStart, setDateStart] = useState(
		dayjs().startOf('month').format(dateFormat),
	)
	const [dateEnd, setDateEnd] = useState(dayjs().format(dateFormat))
	const [promisedDate, setPromisedDate] = useState('')

	const [executer, setExecuter] = useState([])
	const [customerData, setCustomerData] = useState([])
	const [doceAndInsData, setDoceAndInsData] = useState([])
	const [progress, setProgress] = useState(0)
	const dispatch = useDispatch()
	const [data, setData] = useState({
		data: [],
	})
	const [currentPage, setCurrentPage] = useState(0)
	const [businessLoading, setBusinessLoading] = useState(false)
	const [employeeID, setEmployeeID] = useState(executerStore)
	const [executorLoading, setExecutorLoading] = useState(false)
	const [maxPage, setMaxPage] = useState(20)
	const [userFields, setUserFields] = useState([])
	const [page, setPage] = useState(0)

	useEffect(() => {
		getAllGetterMoney()
		doceAndIns()
		userFieldsFn()
	}, [])

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OINV'",
					$select: 'Name,ValidValuesMD,TableName',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const getAllGetterMoney = () => {
		api
			.get(
				`$crossjoin(EmployeesInfo, Departments)?$filter=Departments/Name eq 'Undiruv' and EmployeesInfo/Department eq Departments/Code&$expand=EmployeesInfo($select=EmployeeID,LastName,FirstName,U_CashAccount,U_CardAccount,U_TransAccount),Departments($select=Name,Code)`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=90000',
					},
				},
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setExecuter(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const doceAndIns = () => {
		api
			.get(
				`SQLQueries('latePaymentsMin')/List?date1='${startDateFormatted}T00:00:00Z'&date2='${endDateFormatted}T00:00:00Z'`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=90000',
					},
				},
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setDoceAndInsData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const recovery = (
		startD,
		endD,
		promisedD = '',
		userCardCode = name.includes(',') ? name.split(' , ')[1] : name,
	) => {
		setIsLoading(true)
		customFuncsApi
			.get(
				`installments?date1='${startD === '' ? '2012-01-01' : startD}T00:00:00Z'&date2='${endD === '' ? moment().format('YYYY-MM-DD') : endD}T00:00:00Z&U_PromisedDate='${promisedD === '' ? '' : promisedD + 'T00:00:00Z'}'${clientName.split(' , ')[1] ? `&CardCode='${clientName.split(' , ')[1]}'` : ''}${
					get(getMe, 'Department2.Name', '') === 'Boshqaruv' ||
					get(getMe, 'Department2.Name', '') === 'Undiruvchi1'
						? ''
						: `&U_Employee=${get(getMe, 'EmployeeID', '')}`
				}${status?.length <= 0 ? '' : `&status=${status}`}&$skip=${page * 10}${imei !== '' ? `&IntrSerial=%25${imei}%25` : ''}`,
				{
					params: {
						U_Employee: employeeID?.length <= 0 ? undefined : employeeID,
					},
					headers: {
						Prefer: `odata.maxpagesize=${maxPage}`,
					},
				},
			)
			.then((res) => {
				if (get(res, 'status', 0) === 401) navigate('/login', { replace: true })
				const resData = get(res, 'data.value', [])
				setData({ data: resData })

				setData({
					data: [...resData],
				})
				setIsLoading(false)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
				if (get(err, 'response.status', 0) === 401)
					navigate('/login', { replace: true })

				setIsLoading(false)
			})
	}

	useEffect(() => {
		recovery(dateStart, dateEnd)
	}, [page, maxPage])

	const AddExecutorsForInstallments = async () => {
		setExecutorLoading(true)
		setIsProtsent(true)
		try {
			const installments = await customFuncsApi
				.get(`installments?U_Employee=null`, {
					headers: {
						Prefer: 'odata.maxpagesize=1000000',
					},
				})
				.then((res) => res.data.value)

			const chunkSize = 100
			const totalInstallments = installments?.length

			const sendChunk = async (chunk) => {
				const chunkData = {
					installments: chunk.map((v) => ({
						DocEntry: v.DocEntry,
						InstallmentId: v.InstlmntID,
					})),
					dunners: executer.map((v) => v.EmployeesInfo.EmployeeID),
				}
				await customFuncsApi.post('assignDunners', chunkData)
			}

			for (let i = 0; i < totalInstallments; i += chunkSize) {
				const chunk = installments.slice(i, i + chunkSize)
				await sendChunk(chunk)
				let protsent = Math.floor((i / totalInstallments) * 100)
				setProgress(protsent)
			}
		} catch (error) {
			console.error('Error processing installments in Recovery:', error)
		} finally {
			setExecutorLoading(false)
			setIsProtsent(false)
		}
	}

	// const newDatas = () => {
	// 	if (data.data?.length >= 10) {
	// 		setCurrentPage({ page: currentPage.page + 10 })
	// 		dispatch(setPageStore(currentPage.page + 10))
	// 	} else {
	// 		alert(t('noOtherInfo'))
	// 	}
	// }

	// const oldData = () => {
	// 	if (data.data.oldPage < 0) {
	// 		alert(t('noOtherInfo'))
	// 	} else {
	// 		setCurrentPage({ page: currentPage.page - 10 })
	// 		dispatch(setPageStore(currentPage.page - 10))
	// 	}
	// }

	const newDatas = () => {
		if ((data.data.length = maxPage)) {
			setPage((prevState) => prevState + Number(String(maxPage)[0]))
			setCurrentPage((prevState) => prevState + 1)
			dispatch(setPageStore(currentPage.page + 10))
		} else {
			alert(t('noOtherInfo'))
		}
	}

	const oldData = () => {
		if (page === 0) {
			alert(t('noOtherInfo'))
		} else {
			setPage((prevState) => prevState - Number(String(maxPage)[0]))
			setCurrentPage((prevState) => prevState - 1)
			dispatch(setPageStore(currentPage.page - 10))
		}
	}

	const viewItem = (v) => {
		const queryParams = new URLSearchParams({
			DocEntry: JSON.stringify(v.DocEntry),
			InstlmntID: JSON.stringify(v.InstlmntID),
		}).toString()
		window.open(`/viewRecoveryItem?${queryParams}`, '_blank')
	}

	const searchUser = (a = '') => {
		setClientName(a)
		setBusinessLoading(true)
		const formattedName = a.includes("'") ? a.replace(/'/g, "''") : a

		api
			.get(
				`BusinessPartners?$select=CardCode,CardName,Address,Phone1,GroupCode&$filter=CardType eq 'cCustomer' and (contains(CardName, '${formattedName}') or contains(U_Telephone, '${formattedName}'))`,
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				if (
					!(resData.length === 1) ||
					!a.includes(resData[0].CardName) ||
					!a.includes(resData[0].CardCode)
				)
					setCustomerData(resData)
				if (
					resData.length === 0 &&
					(!a.includes(resData[0].CardName) || !a.includes(resData[0].CardCode))
				) {
					setCustomerData([{ CardName: 'Нет данных', CardCode: '' }])
				}
			})
			.catch((err) => {
				// errorRef.current?.open(
				//   get(JSON.parse(err.response.data), 'error.message', ''),
				// )
			})
			.finally(() => {
				setBusinessLoading(false)
			})
	}

	useEffect(() => {
		const delay = 1000
		let timeoutId
		if (clientName) {
			timeoutId = setTimeout(() => {
				searchUser(clientName)
			}, delay)
		}
		return () => {
			clearTimeout(timeoutId)
		}
	}, [clientName])

	const handleChange = (e) => {
		const newSearchTerm = e.target.value
		if (e.target.value?.includes(' , ')) {
			setUserCardCode(e.target.value?.split(' , ')[1])
		} else {
			setUserCardCode('')
		}
		dispatch(setName(newSearchTerm))
		setClientName(newSearchTerm)
	}

	const distribution = () => {
		distributionMain(0, 50, 1)
		setIsProtsent(true)
	}

	const distributionMain = (currentN, nextN, someN) => {
		const dunners = executer.map((v) => {
			return get(v, 'EmployeesInfo.EmployeeID', 0)
		})
		setProgress(0)
		let someData = doceAndInsData.slice(currentN, nextN).map((v, i) => {
			return {
				DocEntry: v.DocEntry,
				InstallmentId: v.InstlmntID,
			}
		})

		customFuncsApi
			.post(
				`assignDunners`,
				{
					installments: someData,
					dunners,
					Origin: window.origin,
				},
				{
					timeout: 1000 * 60,
					onUploadProgress: (progressEvent) => {
						const { loaded, total } = progressEvent
						let percent = Math.floor((loaded / total) * 100)
						setProgress(+percent)
					},
				},
			)
			.then(() => {
				if (Math.floor(doceAndInsData.length / 50) + 1 > someN) {
					distributionMain(currentN + 50, nextN + 50, someN + 1)
					setnumberProtsent(someN + 1)
				} else {
					alert("Amaliyot to'liq yuklandi")
					setIsProtsent(false)
				}
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
				setIsProtsent(false)
			})
	}

	const onRangeChange = (dates, dateStrings) => {
		if (dates) {
			const start = dates[0]
			const end = dates[1]

			setDateStart(start ? start.format('YYYY-MM-DD') : null)
			setDateEnd(end ? end.format('YYYY-MM-DD') : null)
		} else {
			console.log('Clear')
		}
	}

	return (
		<Layout>
			<RecoveryStyle>
				<div className="container">
					<div style={{ width: '100%' }}>
						<p className="topTitle mb-8">{t('recoverySchedulel')}</p>
						<div className="flex  flex-row xl:items-center items-center gap-2 mt-10">
							<div className="relative w-full">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('client')}
								</p>
								<input
									className={
										'border-[1px] border-[#d9d9d9] p-2 rounded-md w-full  '
									}
									type="text"
									value={clientName}
									onChange={(e) => {
										handleChange(e)
									}}
								/>
								{clientName && (
									<div className="absolute top-15 left-0 bg-white w-full z-10 border-[1px] border-[#d9d9d9] max-h-[300px] overflow-y-scroll">
										{businessLoading ? (
											<ClipLoader size={10} />
										) : (
											customerData.map((v, i) => {
												return (
													<div
														key={i}
														className="p-2 border-b cursor-pointer hover:bg-gray-100"
														onClick={() => {
															if (v.CardName === 'Нет данных') return
															dispatch(setName(`${v.CardName} , ${v.CardCode}`))
															setUserCardCode(v.CardCode)
															setClientName(`${v.CardName} , ${v.CardCode}`)
															setCustomerData([])
														}}
													>
														{v.CardName} , {v.CardCode}
													</div>
												)
											})
										)}
									</div>
								)}
							</div>
							<div className="flex flex-col">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('imei')}
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
									}
									value={imei}
									onChange={(e) => {
										setIMEI(e.target.value)
										dispatch(setImei(e.target.value))
									}}
								/>

								<datalist id="client">
									{customerData.map((v, i) => (
										<option
											key={i}
											value={`${get(v, 'CardName', '')} , ${get(
												v,
												'CardCode',
												'',
											)}`}
										/>
									))}
								</datalist>
							</div>

							<div className="flex flex-col w-full">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('date')}
								</p>
								<RangePicker
									value={[
										dateStart ? dayjs(dateStart, dateFormat) : null,
										dateEnd ? dayjs(dateEnd, dateFormat) : null,
									]}
									format={dateFormat}
									onChange={onRangeChange}
									className='border-[1px] border-[#DFE2E9"] p-2 rounded-md min-w-[240px] w-full '
								/>
							</div>
							<div className="flex flex-col">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('dateDeadline')}
								</p>
								<input
									type="date"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									defaultValue={promisedDate}
									onChange={(v) => {
										setPromisedDate(v.target.value)
									}}
								/>
							</div>
							<div className="flex flex-col">
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									{t('paymentStatus')}
								</p>
								<select
									name="payed"
									id="payed"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									defaultValue={status}
									onChange={(v) => {
										setStatus(v.target.value)
										dispatch(setStatusStore(v.target.value))
									}}
								>
									<option value="all">Все</option>
									<option value="closed">{'Оплачено'}</option>
									<option value="open">{'Не оплачено'}</option>
								</select>
							</div>
							{(get(getMe, 'Department2.Name', '') === 'Undiruvchi1' ||
								get(getMe, 'Department2.Name', '') === 'Boshqaruv') && (
								<div className="flex flex-col flex-1">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										{t('receiver')}
									</p>
									<select
										name="executer"
										id="executer"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[150px]'
										}
										onChange={(v) => {
											setEmployeeID(v.target.value)
											dispatch(setExecuterStore(v.target.value))
										}}
										value={employeeID}
									>
										<option></option>
										<option value="null">Без приёмщика</option>
										{executer.map((v, i) => {
											return (
												<option
													value={get(v, 'EmployeesInfo.EmployeeID', 0)}
													key={i}
													selected={
														v.EmployeesInfo.EmployeeID ===
														get(data, 'U_Employee', '')
													}
												>
													{get(v, 'EmployeesInfo.FirstName', 'Name')}{' '}
													{get(v, 'EmployeesInfo.LastName', 'Surename')}
												</option>
											)
										})}
									</select>
								</div>
							)}

							<Button
								isLoading={isLoading}
								className={'mt-5'}
								onClick={() =>
									recovery(dateStart, dateEnd, promisedDate, userCardCode)
								}
							>
								{t('search')}
							</Button>
						</div>
						<div className="mt-8 mb-2 flex flex-col md:flex-row max-w-md">
							<div className="flex gap-2 items-center">
								<div className="size-6 rounded-full bg-red-200"></div>
								<span> {'<'} 60 день</span>
							</div>
							<div className="flex gap-2 items-center">
								<div className="size-6 rounded-full bg-red-400"></div>
								<span> {'<'} 150 день</span>
							</div>
							<div className="flex gap-2 items-center">
								<div className="size-6 rounded-full bg-black"></div>
								<span> {'>'} 150 день</span>
							</div>
						</div>
						{isLoading ? (
							<ClipLoader
								loading={true}
								size={75}
								aria-label="Loading Spinner"
								className={'loader'}
								data-testid="loader"
							/>
						) : (
							<div className="overflow-y-auto">
								<table className="w-full text-sm text-left  text-gray-700 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												{t('status')}
											</th>
											<th scope="col" className="px-6 py-4 text-center">
												{t('clientCode')}
											</th>
											<th scope="col" className="px-14 py-4 text-center">
												{t('clientName')}
											</th>
											<th scope="col" className="px-14	 py-4">
												{t('product')}
											</th>
											<th scope="col" className="px-14 py-4">
												{t('debt')}
											</th>
											<th scope="col" className="px-10 py-4 text-center">
												{t('dateOfSale')}
											</th>
											<th scope="col" className="px-6 py-4 text-center">
												{t('executor')}
											</th>

											<th scope="col" className="px-6 py-4 text-center">
												{t('status')}
											</th>
											<th scope="col" className="px-6 py-4 text-center">
												{t('dateDeadline')}
											</th>
											<th scope="col" className="px-6 py-4 text-center">
												{t('lastComment')}
											</th>
											<th scope="col" className="px-6 py-4 text-center">
												{t('lastCommentDate')}
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.length > 0 ? (
											data.data.map((v, i) => {
												return (
													<tr
														key={i}
														onClick={() => viewItem(v)}
														className="bg-white border-b  hover:bg-gray-50 "
													>
														<td className="px-6 py-4 font-medium ">
															<div
																className={
																	get(v, 'INV6.PaidToDate', 0) ===
																	get(v, 'InsTotal', 0)
																		? // hover:border hover:border-zinc-400
																			'size-7 rounded-full bg-green-200 '
																		: moment().diff(v.DueDate, 'days') < 60
																			? 'size-7 rounded-full bg-red-200  '
																			: moment().diff(v.DueDate, 'days') < 150
																				? 'size-7 rounded-full bg-red-400 '
																				: 'size-7 rounded-full bg-black '
																}
															></div>
														</td>
														<td className="px-6 py-4 font-medium ">
															{get(v, 'CardCode', 'Код покупателя')}
														</td>
														<td className="px-6 py-4 text-center">
															{get(v, 'CardName', 'Имя покупателя')}
														</td>
														<td className="px-6 py-4 text-center">
															{get(v, 'Dscription', '')}
														</td>
														<td className="px-6 py-4 text-center">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(
																	get(v, 'InsTotal', '') - v['INV6.PaidToDate'],
																)
																.replace(',', '.')}{' '}
															USD
														</td>
														<td className="px-6 py-4 text-center">
															{get(v, 'DueDate', '').slice(0, 10)}{' '}
														</td>
														<td className="px-6 py-4">
															{get(v, 'firstName', '')} {get(v, 'lastName', '')}
														</td>
														<td className="px-6 py-4">
															{get(v, 'U_PROBLEM.Name', '')}
														</td>
														<td className="px-6 py-4">
															{get(v, 'U_PromisedDate', '')}
														</td>
														<td className="px-6 py-4">
															{get(v, 'U_COMMENTS.U_Text', '')}
														</td>

														<td className="px-6 py-4">
															{get(v, 'U_COMMENTS.U_CreateTime', '')
																? moment(
																		get(v, 'U_COMMENTS.U_CreateTime', ''),
																	).format('YYYY-MM-DD HH:mm')
																: ''}
														</td>
													</tr>
												)
											})
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description={t('noInfo')}
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>

								<div className="between mb-5">
									<div className="flex items-center">
										<Button
											disabled={page === 0}
											className={'btn'}
											onClick={oldData}
										>
											{'<'}
										</Button>
										<p className={'mr-2'}>
											<p>{currentPage + 1}</p>
										</p>
										<Button
											disabled={data.data?.length < maxPage}
											className={'btn'}
											onClick={newDatas}
										>
											{'>'}
										</Button>
									</div>

									<select
										value={maxPage}
										className="w-[50px] rounded-xl flex text-center"
										onChange={(e) => {
											setMaxPage(e.target.value)
											setPage(0)
											setCurrentPage(0)
										}}
									>
										<option value="10">10</option>
										<option value="20">20</option>
										<option value="30">30</option>
										<option value="50">50</option>
										<option value="80">80</option>
									</select>
									<div className={'flex gap-4'}>
										{get(getMe, 'Department2.Name', '') !== 'Undiruv' &&
										doceAndInsData?.length > 1 ? (
											<Button
												className="btnSome"
												isLoading={executorLoading}
												onClick={AddExecutorsForInstallments}
											>
												{t('addNewDebtors')}
											</Button>
										) : null}
										{get(getMe, 'Department2.Name', '') !== 'Undiruv' &&
										doceAndInsData?.length > 1 ? (
											<Button
												className="btnSome"
												onClick={distribution}
												isLoading={isLoading}
												btnStyle={{ width: 300 }}
												hoverBtnStyle={{ width: 300 }}
											>
												{t('distributeAllDebtors')}
											</Button>
										) : null}
									</div>
								</div>
							</div>
						)}

						{isProtsent ? (
							<>
								<p className="progres">{t('waitProgress')} </p>

								<Progressbar bgcolor="green" progress={progress} height={23} />
							</>
						) : null}
					</div>
				</div>
			</RecoveryStyle>
			<>
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
				<SuccessModal getRef={(r) => (successRef.current = r)} />
				<WarningModal getRef={(r) => (warningRef.current = r)} />
			</>
		</Layout>
	)
}

export default Recovery
