import React, { memo, useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import { get } from 'lodash'
import api from '../../../api'
import { ErrorModal } from '..'
import CreateUserStyle from './CreateUserStyle'
import Button from '../../Button'
import { useSelector } from 'react-redux'
import customMainApi from '../../../api'
import ClipLoader from 'react-spinners/ClipLoader'
import formatPhoneNumber from '../../../helpers/PhoneWithSpaces'
import { useTranslation } from 'react-i18next'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',

		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		border: 'none',
		borderRadius: 15,
		overflowX: 'auto',
	},
}

const CreateIcloudModal = (props) => {
	const { getRef = () => {}, onUpdated = () => {}, onClose = () => {} } = props
	const { t } = useTranslation()

	const errorRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [phone, setPhone] = useState('')
	const [userFields, setUserFields] = useState([])
	const [userFields2, setUserFields2] = useState([])

	const [isLoading, setIsLoading] = useState(false)
	const [bpLoading, setBpLoading] = useState(false)
	const [typeAccount, setTypeAccount] = useState('')
	const [password, setPassword] = useState('')
	const [name, setName] = useState('')
	const [status, setStatus] = useState('')

	useEffect(() => {
		const ref = {
			open: () => {
				setIsOpenModal(true)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])
	useEffect(() => {
		userFieldsFn()
		userFieldsFn2()
	}, [])

	const submit = () => {
		setIsLoading(true)
		let url = `Security`
		const generateRandomKey = () => {
			const characters =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randomKey = ''
			for (let i = 0; i < 7; i++) {
				randomKey += characters.charAt(
					Math.floor(Math.random() * characters.length),
				)
			}
			return randomKey
		}

		const randomKey = generateRandomKey()
		api
			.post(url, {
				Code: randomKey,
				Name: name,
				U_Whichtelephone: `${phone}`,
				U_Password: password,
				U_Status: status,
				U_TypeAccount: typeAccount,
			})
			.then(() => {
				onUpdated()
				setIsOpenModal(false)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => setIsLoading(false))
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OINV'",
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const userFieldsFn2 = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq '@Security'",
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setUserFields2(JSON.parse(res.data).value)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card">
					<p className="title">{t('addIcloud')}</p>
					{bpLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="flex flex-col gap-4 mt-10">
								<div className="flex items-center gap-2">
									<div className="flex flex-col gap-2 items-start w-full">
										<p>{t('icloud')}</p>
										<input
											type="text"
											placeholder={t('icloud')}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
											}
											value={name}
											onChange={(v) => setName(v.target.value)}
										/>
									</div>
									<div className="flex flex-col gap-2 items-start w-full">
										<p>{t('phone')}</p>
										<input
											type="text"
											placeholder={t('phone')}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
											}
											value={phone}
											onChange={(v) => setPhone(v.target.value)}
										/>
									</div>
								</div>

								<div className="flex items-center gap-2">
									<div className="flex flex-col gap-2 items-start w-full">
										<p>{t('password')}</p>
										<input
											type="text"
											placeholder={t('password')}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
											}
											value={password}
											onChange={(v) => setPassword(v.target.value)}
										/>
									</div>
									<div className="flex flex-col gap-2 items-start w-full">
										<p>{t('typeAccount')}</p>
										<select
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
											}
											value={typeAccount}
											onChange={(v) => setTypeAccount(v.target.value)}
										>
											<option value=""></option>
											{userFields
												?.find((item) => item.Name === 'TypeAccount')
												?.ValidValuesMD.map((value) => (
													<option key={value.Value} value={value.Value}>
														{value.Description}
													</option>
												))}
										</select>
									</div>
								</div>
								<div className="flex flex-col gap-2 items-start w-[50%]">
									<p>{t('status')}</p>
									<select
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
										}
										value={status}
										onChange={(v) => setStatus(v.target.value)}
									>
										<option value={''}></option>
										{userFields2
											?.find((item) => item.Name === 'Status')
											?.ValidValuesMD.map((value) => (
												<option value={value.Value}>{value.Description}</option>
											))}
									</select>
								</div>
							</div>

							<div className="centerCard mt-10">
								<button
									className="bg-red-500 text-white py-2 px-4 rounded-md btn"
									onClick={() => {
										onClose()
										setIsOpenModal(false)
									}}
								>
									{t('no')}
								</button>
								<Button
									isLoading={isLoading}
									className={'btn'}
									onClick={submit}
								>
									{t('yes')}
								</Button>
							</div>
						</>
					)}
				</div>
			</CreateUserStyle>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
		</Modal>
	)
}

export default memo(CreateIcloudModal)
