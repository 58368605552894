import React, { useEffect, useRef, useState } from 'react'
import Purchases from '../Purchases/Purchases'
import Button from '../../components/Button'
import { customFuncsApi } from '../../api'
import _, { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import moment from 'moment'
import customMainApi from '../../api/index'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'

const ForgivvedPurchases = () => {
	const errorRef = useRef()
	const updateRef = useRef()
	const inputRef = useRef(null)
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState(false)
	const [bpLoading, setBpLoading] = useState(false)

	const [cardCode, setCardCode] = useState('')
	const [bp, setBp] = useState([])
	const [data, setData] = useState({
		data: [],
	})
	const [pagination, setPagination] = useState({
		page: 0,
	})
	const search = (cardCode) => {
		setIsLoading(true)
		customFuncsApi
			.get(
				`XsSql/getDebtWaivers?$skip=${pagination.page * 20}${cardCode ? `&CardCode=${cardCode ? cardCode : undefined}` : ''}`,

				// `Invoices?$filter=CardCode eq '${cardCode}' and DocumentStatus eq 'bost_Open'&$select=DocEntry,DocNum,CardCode,CardName,DocDate,DocDueDate,DocTotal`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=20',
					},
				},
			)
			.then((res) => {
				const resData = res.data.value

				setData({
					data: [...resData],
				})
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err))
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				setIsLoading(false)
			})
	}

	const getBusinessPartners = async (name = '') => {
		if (name.length <= 0) {
			setBp([])
			return
		}
		setBpLoading(true)

		const data = await customMainApi
			.get(
				`BusinessPartners?$select=CardCode,CardName&$filter=contains(CardName,'${name}') and Frozen eq 'tNO' and CardType eq 'cCustomer'`,
			)
			.then((res) => {
				return JSON.parse(res.data).value
			})

		const selectElementData = data.map((item) => {
			return {
				value: item.CardCode,
				label: item.CardName,
			}
		})

		setBp(selectElementData)
		setBpLoading(false)
	}

	const handleBusinessPartner = _.debounce((e) => {
		getBusinessPartners(e.target.value)
	}, 1000)

	const handleBusinessPartnerClick = (event, code) => {
		if (inputRef.current) {
			inputRef.current.value = event.target.innerText
		}
		setCardCode(code)
		search(code)
		setBp([])
	}

	useEffect(() => {
		search(cardCode)
	}, [cardCode])

	const viewItem = (v) => {
		updateRef.current?.open(v)
	}

	const newDatas = () => {
		if (data.data.length < 20) {
			alert(t('noOtherInfo'))
		} else {
			setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
		}
	}

	const oldData = () => {
		if (pagination.page > 0) {
			setPagination((prev) => ({ ...prev, page: prev.page - 1 }))
		} else {
			alert(t('noOtherInfo'))
		}
	}

	return (
		<Layout>
			<Purchases>
				<div className="container">
					<p className="productTitle">{t('discounts')}</p>
					<div className="flex items-center justify-between">
						<div className="searchable-select flex-1">
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('clients')}
							</p>
							<div className="relative w-full">
								<input
									className={
										'border-[1px] border-[#DFE2E9"] w-[300px] p-2 rounded-md '
									}
									ref={inputRef}
									type="text"
									onChange={handleBusinessPartner}
								/>
								{bpLoading && (
									<div className="absolute top-3 right-3">
										<ClipLoader size={20} color="#0A4D68" />
									</div>
								)}
							</div>

							{!!bp.length && (
								<div className="searchable-select-list">
									{bp.map((item) => {
										return (
											<div
												key={item.value}
												className="searchable-select-item"
												onClick={(event) =>
													handleBusinessPartnerClick(event, item.value)
												}
											>
												{item.label}
											</div>
										)
									})}
								</div>
							)}
						</div>
						<div>
							<Button
								className={'btn'}
								onClick={() =>
									window.open('/add-forgivved-purchases', '_blank')
								}
							>
								{t('add')}
							</Button>
						</div>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto my-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												{t('customer')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('model')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('totalPrice2')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('discoutPrice')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('discountDate')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('comment')}
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.length > 0 ? (
											data.data.map((v, i) => (
												<tr
													key={i}
													className="bg-white border-b  hover:bg-gray-50 "
													onClick={() => viewItem(v)}
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{v.CardName || '-'}
													</td>
													<td className="px-6 py-4">{v.Dscription || '-'}</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(v['OINV.DocTotal'])
															.replace(',', '.')}
														USD
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(v['ORCT.DocTotal'])
															.replace(',', '.')}{' '}
														USD
													</td>
													<td className="px-6 py-4">
														{moment(v.DocDate).format('DD-MM-YYYY')}
													</td>
													<td className="px-6 py-4">{v.Comments}</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description={t('noInfo')}
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>

							<div className="topCard w-full ">
								<div className="flex w-full items-center justify-between">
									<div className="flex">
										<Button
											className={'btn'}
											onClick={oldData}
											btnStyle={{ marginRight: 10 }}
										>
											{'<'}
										</Button>
										<Button className={'btn'} onClick={newDatas}>
											{'>'}
										</Button>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</Purchases>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default ForgivvedPurchases
