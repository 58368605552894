import React, { useEffect, useRef, useState } from 'react'
import ReportsModalStyle from './ReportsModalStyle'
import Modal from 'react-modal'
import Button from '../../Button'

import { get } from 'lodash'
import { customFuncsApi } from '../../../api'
import { useSelector } from 'react-redux'
import customMainApi from '../../../api'
import { ErrorModal, SuccessModal } from '../index'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
	},
	overlay: {
		background: '#00000099',
	},
}

const PurchaseReturnsModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
	onUpdated = () => {},
}) => {
	const { getMe } = useSelector((state) => state.main)
	const { t } = useTranslation()

	const errorModalRef = useRef()
	const successModalRef = useRef()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const [userFields, setUserFields] = useState([])
	const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
	const [item, setItem] = useState('')
	const [price, setPrice] = useState('')
	const [debt, setDebt] = useState('')
	const [docEntry, setDocEntry] = useState('')
	const [status, setStatus] = useState('bosh')
	const [userFields2, setUserFields2] = useState([])

	useEffect(() => {
		const ref = {
			open: (data) => {
				setIsOpenModal(true)

				setDocEntry(
					get(data, 'DocEntry', null) !== null ? get(data, 'DocEntry', '') : '',
				)
				setItem(
					get(data, 'Dscription', null) !== null
						? get(data, 'Dscription', '')
						: '',
				)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const submit = async () => {
		setLoading(true)
		try {
			await customFuncsApi.post('addItemReturnFromDebtor', {
				DocDate: date,
				invoiceDocEntry: docEntry,
				itemPrice: price,
				U_CashFlow: '5',
				'Security.U_Status': status,
				SalesPersonCode: getMe.SalesPersonCode || -1,
			})
			setTimeout(() => {
				onConfirm()
				onUpdated()
				setIsOpenModal(false)
			}, 2000)
			successModalRef.current?.open(t('returnSuccess'))
		} catch (error) {
			let errorMessage = 'An unknown error occurred'

			if (error.response) {
				console.error('Error response in Purchase Return Modal:', error.response)
				if (error.response.data) {
					console.error('Error response data in Purchase Return Modal:', error.response.data)

					try {
						errorMessage =
							error.response.data.PurchaseInvoice.error !== null
								? error.response.data.PurchaseInvoice.error.error.message
								: error.response.data.payments.error.message +
									"!       Sotuv yaratildi lekin to'lov yaratilmadi SAP dan tekshirib oling! "
						errorModalRef.current?.open(errorMessage)
					} catch (parseError) {
						console.error('Parsing error in Purchase Return Modal :', parseError)
						errorMessage = 'Failed to parse error response'
					}
				}
			}
		}
		setLoading(false)
	}
	const getInvoices = () => {
		customMainApi
			.get(`Invoices(${docEntry})`, {
				params: {
					$select:
						'DocDate,DocTotal,CardName,CardCode,SalesPersonCode,U_Security,PaidToDate',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data))
				setDebt(
					get(JSON.parse(res.data), 'DocTotal', 0) -
						get(JSON.parse(res.data), 'PaidToDate', 0),
				)
				getIclouds(get(JSON.parse(res.data), 'U_Security', 0))
			})
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq '@Security'",
				},
			})
			.then((res) => {
				setUserFields2(JSON.parse(res.data).value)
			})
	}
	useEffect(() => {
		if (docEntry) {
			getInvoices()
			const today = moment().format('YYYY-MM-DD')
			setDate(today)
		}
		userFieldsFn()
	}, [docEntry])

	const getIclouds = (code) => {
		if (code) {
			customMainApi
				.get(`Security('${code}')`, {
					params: {
						$select:
							'Code,Name,U_Whichtelephone,U_Password,U_Status,U_TypeAccount',
					},
					headers: {
						Prefer: 'odata.maxpagesize=100',
					},
				})
				.then((res) => {
					setStatus(JSON.parse(res.data).value.U_Status)
				})
		}
	}
	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<ReportsModalStyle>
				<div className="card">
					<p className="title">{t('addReturn')}</p>
					<div className="flex flex-col gap-4 mb-10">
						<div className="flexCard">
							<div className="flex flex-col items-start gap-3">
								<p>{t('date')}</p>
								<input
									type="date"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
									}
									value={date}
									onChange={(v) => setDate(v.target.value)}
								/>
							</div>
							<div className="flex flex-col items-start gap-3">
								<p>{t('customer')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(userFields, 'CardName', '')}
									disabled={true}
								/>
							</div>
							<div className="flex flex-col items-start gap-3">
								<p>{t('itemPrice')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(userFields, 'DocTotal', '')}
									disabled={true}
								/>
							</div>
						</div>
						<div className="flexCard">
							<div className="flex flex-col items-start gap-3">
								<p>{t('model')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
									}
									value={item}
									disabled={true}
								/>
							</div>
							<div className="flex flex-col items-start gap-3">
								<p>{t('debt')}</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
									}
									value={debt}
									disabled={true}
								/>
							</div>
						</div>

						<div className="flexCard">
							<div className="flex flex-col items-start gap-3">
								<p>{t('itemPrice2')}</p>
								<input
									type="number"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
									}
									value={price}
									onChange={(e) => setPrice(e.target.value)}
								/>
							</div>
						</div>
						{/* <div className="flexCard">
									<div className="flex flex-col items-start gap-3">
										<p>{t('Securitystatus')}</p>
										<select
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
											}
											value={status}
											onChange={(v) => setStatus(v.target.value)}
										>
											{' '}
											<option value={''}></option>
											{userFields2
												?.find((item) => item.Name === 'Status')
												?.ValidValuesMD.map((value) => (
													<option value={value.Value}>{value.Description}</option>
												))}
										</select>
									</div>
								</div> */}
					</div>
					<div className="centerCard">
						<button
							className="bg-red-500 text-white py-2 px-4 rounded-md btn"
							onClick={() => {
								onClose()
								setIsOpenModal(false)
							}}
						>
							{t('no')}
						</button>
						<Button isLoading={loading} className={'btn'} onClick={submit}>
							{t('yes')}
						</Button>
					</div>
				</div>
			</ReportsModalStyle>
			<SuccessModal
				getRef={(r) => {
					successModalRef.current = r
				}}
			/>

			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
		</Modal>
	)
}

export default PurchaseReturnsModal
